/** @jsx jsx */
import { Text, jsx } from 'theme-ui'
import { ErrorMessage } from 'formik'

export const EMessage = ({ name, ...rest }: { name: string }) => (
  <ErrorMessage name={name}>
    {msg => (
      <Text sx={{ color: 'red' }} {...rest}>
        {msg}
      </Text>
    )}
  </ErrorMessage>
)
