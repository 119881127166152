import React from 'react'

import Admin from '@components/Admin'
import { initSocket } from '@contexts/Socket'

const StreamPage = () => {
  React.useEffect(() => {
    initSocket()
  }, [])

  return <Admin />
}

export default StreamPage
