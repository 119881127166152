/** @jsx jsx */
import React from 'react'
import { jsx, Heading, Box, Text, Flex, Grid, Spinner } from 'theme-ui'
import { FieldArray, Form, Field } from 'formik'
import { AdminInput as Input } from '@components/forms/Input'

import { EMessage } from '@components/forms/EMessage'
import { initialDJValue, initialLinkValue } from '@components/Admin'
import { Button } from '@components/Button'

const DJ = ({ dj, index, last, arrayHelpers }) => (
  <React.Fragment>
    <Grid
      sx={{
        gridRowGap: 0,
        p: 2,
        gridColumnGap: 2,
        bg: 'rgba(0,0,0,0.05)',
        gridTemplateColumns: '1fr 1fr max-content'
      }}
    >
      <Field
        name={`socialLinks.${index}.name`}
        placeholder="DJ name"
        component={Input}
        sx={{ gridColumn: 'span 2' }}
      />

      <button
        type="button"
        onClick={() => arrayHelpers.remove(index)}
        sx={{
          alignSelf: 'center',
          borderRadius: '2em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2,
          border: 'none',
          color: 'white',
          cursor: 'pointer',
          bg: 'red',
          svg: {
            height: '2em',
            fill: 'black'
          },
          '&:hover': {
            bg: 'white'
          }
        }}
      >
        <svg viewBox="0 0 24 24">
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" />
          <path fill="none" d="M0 0h24v24H0z" />
        </svg>
      </button>
      <FieldArray name={`socialLinks.${index}.links`}>
        {linkHelpers => (
          <div sx={{ gridColumn: 'span 3', mt: 2 }}>
            {dj.links && dj.links.length > 0 ? (
              dj.links.map((link, linkIndex) => (
                <Box key={`link-${index}-${linkIndex}`} sx={{ mb: [2, 0] }}>
                  <Grid
                    sx={{
                      gridTemplateColumns: [
                        '1fr max-content',
                        '1fr 2fr max-content'
                      ],
                      gridTemplateAreas: [
                        `'name actions' 'url actions'`,
                        `'name url actions'`
                      ],
                      gridGap: 0
                    }}
                  >
                    <Field
                      name={`socialLinks.${index}.links.${linkIndex}.name`}
                      component={Input}
                      placeholder="FB / Insta etc."
                      sx={{ gridArea: 'name', borderRight: [null, 0] }}
                    />
                    <Field
                      name={`socialLinks.${index}.links.${linkIndex}.url`}
                      component={Input}
                      placeholder="URL"
                      sx={{ gridArea: 'url' }}
                    />
                    <button
                      type="button"
                      onClick={() => linkHelpers.remove(linkIndex)}
                      sx={{
                        borderRadius: '2em',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 0,
                        border: 'none',
                        color: 'white',
                        alignSelf: 'center',
                        cursor: 'pointer',
                        background: 'none',
                        ml: 2,
                        gridArea: 'actions',
                        svg: {
                          height: '2em',
                          fill: 'black'
                        },
                        '&:hover': {
                          svg: {
                            fill: 'red'
                          }
                        }
                      }}
                    >
                      <svg viewBox="0 0 24 24">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z" />
                      </svg>
                    </button>
                  </Grid>
                  <EMessage
                    name={`socialLinks.${index}.links.${linkIndex}.name`}
                  />
                  <EMessage
                    name={`socialLinks.${index}.links.${linkIndex}.url`}
                  />
                  {linkIndex === dj.links.length - 1 && (
                    <Button
                      variant="small"
                      onClick={() => linkHelpers.push(initialLinkValue)}
                    >
                      Add a link
                    </Button>
                  )}
                </Box>
              ))
            ) : (
              <Box>
                <Text>No links yet</Text>
                <Button
                  variant="small"
                  onClick={() => linkHelpers.push(initialLinkValue)}
                >
                  Add a link
                </Button>
              </Box>
            )}
          </div>
        )}
      </FieldArray>
    </Grid>
    <Box>
      {last && (
        <Button
          variant="small"
          onClick={() => arrayHelpers.push(initialDJValue)}
        >
          Add another DJ
        </Button>
      )}
    </Box>
  </React.Fragment>
)

export default DJ
