/** @jsx jsx */
import React from 'react'
import { jsx, Heading, Box, Text, Flex, Grid, Spinner } from 'theme-ui'
import { RouteComponentProps } from '@reach/router'
import { Formik, FieldArray, Form, Field, ErrorMessage } from 'formik'
import { AdminInput as Input } from '@components/forms/Input'
import * as yup from 'yup'
import shallow from 'zustand/shallow'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@reach/disclosure'

import { updateAdmin } from '@contexts/Socket'
import { Button } from '@components/Button'
import Layout from '@components/Layout'
import SEO from '@components/SEO'
import { useStore } from '@components/api'
import { Header } from '@components/Header'
import DJ from '@components/forms/DJ'
import { EMessage } from '@components/forms/EMessage'

const schema = yup.object().shape({
  status: yup.string().oneOf(['offline', 'live', 'rerun']).required(),
  password: yup.string().required('Enter a password'),
  color: yup.string().required('Please select a color'),
  title: yup.string().required('Please enter a stream title'),
  showLinksOffline: yup.boolean(),
  showDonations: yup.boolean(),
  socialLinks: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Please enter a DJ name'),
      links: yup.array().of(
        yup.object().shape({
          name: yup
            .string()
            .required('Enter a name for this URL (ie. Facebook)'),
          url: yup
            .string()
            .url('Enter a valid URL')
            .required('Enter a valid URL'),
        })
      ),
    })
  ),
  streamURL: yup
    .string()
    .url('Please enter a valid stream URL')
    .matches(/\.m3u8$/, 'Please enter a valid stream URL (ends in .m3u8)')
    .required('Please enter a valid stream URL'),
  testStreamURL: yup
    .string()
    .url('Please enter a valid stream URL')
    .matches(/\.m3u8$/, 'Please enter a valid stream URL (ends in .m3u8)')
    .required('Please enter a valid stream URL'),
  rerunStreamURL: yup
    .string()
    .url('Please enter a valid stream URL')
    .matches(/\.m3u8$/, 'Please enter a valid stream URL (ends in .m3u8)')
    .required('Please enter a valid stream URL'),
})

export const initialLinkValue = { name: '', url: '' }
export const initialDJValue = { name: '', links: [initialLinkValue] }

const Admin = (props: RouteComponentProps) => {
  const {
    color,
    live,
    rerun,
    streamURL,
    testStreamURL,
    rerunStreamURL,
    streamcount,
    title,
    socialLinks,
    showLinksOffline,
    showDonations,
  } = useStore(
    ({
      streamcount,
      color,
      live,
      rerun,
      title,
      streamURL,
      testStreamURL,
      rerunStreamURL,
      socialLinks,
      showLinksOffline,
      showDonations,
    }) => ({
      streamcount,
      color,
      live,
      rerun,
      title,
      socialLinks,
      streamURL,
      testStreamURL,
      rerunStreamURL,
      showLinksOffline,
      showDonations,
    }),
    shallow
  )

  React.useEffect(() => {
    document.body.style.backgroundColor = color
  }, [color])

  return (
    <Layout>
      <SEO title="Radio Radio Live Admin" />
      <Header admin />
      <div sx={{ p: [3, 4], maxWidth: 600, m: '0 auto' }}>
        <Heading>Settings</Heading>
        {streamcount === 0 ? (
          <Spinner />
        ) : (
          <Formik
            onSubmit={({ password, status, ...rest }, { setSubmitting }) => {
              updateAdmin(password, {
                ...rest,
                live: status === 'live',
                rerun: status === 'rerun',
              })
              setSubmitting(false)
            }}
            initialValues={{
              status: live ? 'live' : rerun ? 'rerun' : 'offline',
              password: '',
              color,
              title,
              socialLinks,
              streamURL,
              testStreamURL,
              rerunStreamURL,
              showLinksOffline,
              showDonations,
            }}
            validationSchema={schema}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <Grid>
                  <label>
                    Password
                    <Field name="password" type="password" component={Input} />
                  </label>
                  <Grid sx={{ gridTemplateColumns: 'repeat(3, max-content)' }}>
                    <label>
                      <Field
                        name="status"
                        type="radio"
                        value="offline"
                        checked={values.status === 'offline'}
                      />
                      Offline
                    </label>
                    <label>
                      <Field
                        name="status"
                        type="radio"
                        value="live"
                        checked={values.status === 'live'}
                      />
                      Live
                    </label>
                    <label>
                      <Field
                        name="status"
                        type="radio"
                        value="rerun"
                        checked={values.status === 'rerun'}
                      />
                      Rerun
                    </label>
                  </Grid>
                  <label>
                    Title
                    <Field name="title" placeholder="Title" component={Input} />
                  </label>
                  <label>
                    <Text>Color</Text>
                    <Flex sx={{ alignItems: 'center' }}>
                      {values.color && (
                        <Box
                          sx={{
                            border: '1px solid black',
                            width: '2em',
                            height: '2em',
                            mr: 2,
                            bg: values.color,
                          }}
                        ></Box>
                      )}
                      <Field as="select" name="color">
                        <option value="#FC8253">Red</option>
                        <option value="#FFF200">ADE Yellow</option>
                        <option value="#6058DA">Purple</option>
                        <option value="#99CFFF">Blue</option>
                        <option value="#45CEA6">Green</option>
                        <option value="#FDB1C1">Pink</option>
                      </Field>
                    </Flex>
                  </label>
                  <label>
                    <Field
                      name="showLinksOffline"
                      type="checkbox"
                      checked={values.showLinksOffline}
                    />
                    Show links while offline?
                  </label>
                  <label>
                    <Field
                      name="showDonations"
                      type="checkbox"
                      checked={values.showDonations}
                    />
                    Show floating donation names?
                  </label>
                  <FieldArray name="socialLinks">
                    {(arrayHelpers) => (
                      <Grid sx={{ p: 1, bg: 'rgba(0,0,0,0.05)' }}>
                        {values.socialLinks && values.socialLinks.length > 0 ? (
                          values.socialLinks.map((dj, index) => (
                            <DJ
                              key={`social-${index}`}
                              dj={dj}
                              index={index}
                              arrayHelpers={arrayHelpers}
                              last={index === values.socialLinks.length - 1}
                            />
                          ))
                        ) : (
                          <Box>
                            <Text>No DJ links yet</Text>
                            <Button
                              variant="small"
                              onClick={() => arrayHelpers.push(initialDJValue)}
                            >
                              Add DJ links
                            </Button>
                          </Box>
                        )}
                      </Grid>
                    )}
                  </FieldArray>
                  <Box sx={{ bg: 'rgba(0,0,0,0.05)', p: 2 }}>
                    <Disclosure>
                      <DisclosureButton sx={{ mb: 1 }}>
                        Toggle advanced options
                      </DisclosureButton>
                      <DisclosurePanel>
                        <Grid>
                          <Text sx={{ color: 'red' }}>
                            Don't change these unless you know what you're
                            doing!
                          </Text>
                          <label>
                            LIVE Stream URL
                            <Field
                              name="streamURL"
                              placeholder="LIVE stream URL"
                              component={Input}
                            />
                          </label>
                          <ErrorMessage name="streamURL" />

                          <label>
                            Test stream URL
                            <Field
                              name="testStreamURL"
                              placeholder="Test stream URL"
                              component={Input}
                            />
                          </label>
                          <EMessage name="testStreamURL" />

                          <label>
                            Rerun stream URL
                            <Field
                              name="rerunStreamURL"
                              placeholder="Rerun stream URL"
                              component={Input}
                            />
                          </label>
                          <EMessage name="rerunStreamURL" />
                        </Grid>
                      </DisclosurePanel>
                    </Disclosure>
                  </Box>

                  <Box mt={2}>
                    <EMessage name="password" />
                    <EMessage name="title" />
                    <EMessage name="stream" />
                    <EMessage name="color" />
                  </Box>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    sx={{
                      m: 0,
                      display: 'block',
                      border: 'none',
                      color: 'white',
                      bg: 'black',
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </Layout>
  )
}

export default Admin
